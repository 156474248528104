import { useState } from "react";
import '../asset/css/SignUp.scss';
import { useNavigate } from "react-router-dom"
import axios from "../axios";

const SignUp = () => {

    const navigate = useNavigate()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const onTap = async () => {
        axios.post(
            '/signUp', {
            email: email,
            password: password
        }
        ).then((response) => {
            const res = response.data
            console.log(res.data.token)
            // ストーリー一覧画面に移動
            navigate('/');
        });
    }

    const moveToLogin = async () => {
        navigate('/login')
    }

    return (
        <>
            <h2 class='header-text'>新規登録</h2>
            <form className='login-form'>
                <div className='email-area'>
                    <div>メールアドレス</div>
                    <input name='email' onChange={(event) => setEmail(event.target.value)} />
                </div>
                <div className='password-area'>
                    <div>パスワード</div>
                    <input type='password' name='password' onChange={(event) => setPassword(event.target.value)} />
                </div>
            </form>
            <div className='login-button-area'>
                <button disabled={(email === '' || email === undefined) ||
                    (password === '' || password === undefined)} onClick={onTap}>同意して登録する
                </button>
            </div>
            <div className='create-account-link'>
                <span>新規登録には利用規約とプライバシーポリシーへの同意が必要です</span>
            </div>
            <div className='create-account-link'>
                <span onClick={moveToLogin}>ログインはこちら</span>
            </div>
        </>
    )
}

export default SignUp;
