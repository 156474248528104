import { useCookies } from "react-cookie";
import '../asset/css/DropDownMenu.scss'
import { useNavigate } from "react-router-dom"


const DropDownMenu = ({onClose}) => {
    const navigate = useNavigate()

    // 使用しない場合も記述する必要がある
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const handlerLogout = () => {
        onClose();
        removeCookie('token');
    }

    const moveAccountInfo = () => {
        onClose();
        navigate('/accountInformation');
    }

    return (
        <ul className="drop-down">
            <li className='drop-down-item'><button onClick={handlerLogout}>ログアウト</button></li>
            <hr className='drop-down-divider' />
            <li className='drop-down-item'><button onClick={moveAccountInfo}>アカウント設定</button></li>
        </ul>
    );
}

export default DropDownMenu;
