import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import StoryList from './page/StoryList';
import StoryDetail from './page/StoryDetail';
import reportWebVitals from './reportWebVitals';
import Login from './page/Login'
import SignUp from "./page/SignUp";
import AccountInformation from './page/AccountInformation';
import MenuButton from './component/MenuButton';
import {BrowserRouter, Routes, Route, useLocation, useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </>
);

function App() {
    const location = useLocation();
    const [cookies] = useCookies(['token']);
    const navigate = useNavigate()

    useEffect(() => {
        const notRedirectPageList = ['/login', '/signUp'];
        // ログイン以外のページ且つcookieが無い場合はログインページに遷移する
        if (!notRedirectPageList.includes(location.pathname) && !cookies.token) {
            navigate('/login')
        } else if (cookies.token && location.pathname === notRedirectPageList[0]) {
            navigate('/')
        }
        // TODO ここで404ページの対応を入れたほうが良い
    }, [cookies.token, location, navigate]);

    return (
        <>
            <div className="header">
                <a className='logo' href={'/'}>et-cetera</a>
              {
                cookies.token &&
                  <MenuButton className="menu-button"/>
              }
            </div>
            <Routes>
                <Route index element={<StoryList/>}/>
                <Route path="/" element={<StoryList/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/signUp" element={<SignUp/>}/>
                <Route path="/detail" element={<StoryDetail/>}/>
                <Route path='/accountInformation' element={<AccountInformation/>}/>
            </Routes>
        </>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
