import { useState } from "react";
import ThreePointLeader from "../asset/image/three-point-leader.svg"
import DropDownMenu from './DropDownMenu';


const MenuButton = () => {
    const [showDropdown, setShowDropdown] = useState(false);

    const handleDropdownClick = () => {
        setShowDropdown(!showDropdown);
    };

    return (
        <div>
            <div className="ellipsis-icon" onClick={handleDropdownClick} >
                <img src={ThreePointLeader} />
            </div>
            {showDropdown && <DropDownMenu onClose={() => setShowDropdown(false)} />}
        </div>
    )
}

export default MenuButton
