import ChangeEmail from "../component/ChangeEmail"
import ChangePassword from "../component/ChangePassword"

const AccountInformation = () => {
    return (
        <>
            <ChangeEmail />
            <hr style={{'margin-top': '2rem'}} />
            <ChangePassword />
        </>
    )
}

export default AccountInformation