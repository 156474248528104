import { useState } from "react";

const ChangePassword = () => {

    const [password, setPassword] = useState()
    const [newPassword, setNewPassword] = useState()
    const [newPasswordForConfirmation, setNewPasswordForConfirmation] = useState()

    const onTap = async () => {
        // TODO: パスワード変更処理
        alert('パスワードの変更をしました');
    }

    return (
        <>
            <h2 class='header-text'>パスワード変更</h2>
            <form className='login-form'>
                <div className='password-area'>
                    <div>現在のパスワード</div>
                    <input type='password' name='password' onChange={(event) => setPassword(event.target.value)} />
                    <div style={{ 'padding-top': '1rem' }}>新しいパスワード</div>
                    <input type='password' name='password' onChange={(event) => setNewPassword(event.target.value)} />
                    <div style={{ 'padding-top': '1rem' }}>新しいパスワード(確認用)</div>
                    <input type='password' name='password' onChange={(event) => setNewPasswordForConfirmation(event.target.value)} />
                </div>
            </form>
            <div className='login-button-area'>
                <button disabled={
                    (
                        password === '' || password === undefined
                        || newPassword === '' || newPassword === undefined
                        || newPasswordForConfirmation === '' || newPasswordForConfirmation === undefined
                        || newPassword !== newPasswordForConfirmation
                    )
                } onClick={onTap}>変更</button>
            </div>
        </>
    );
}

export default ChangePassword