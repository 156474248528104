import { useState } from "react";
import '../asset/css/ChangeEmail.scss'

const ChangeEmail = () => {

    const [email, setEmail] = useState()

    const onTap = async () => {
        // TODO: メールアドレス変更処理
        alert('メールアドレスの変更をしました');
    }

    return (
        <>
            <h2 class='header-text'>メールアドレス変更</h2>
            <form className='login-form'>
                <div className='email-area'>
                    <div>現在のメールアドレス</div>
                    {/* TODO: 取得したメールアドレスを表示する */}
                    <div>test0@example.com</div>
                    <div style={{ 'padding-top': '2rem' }}>新しいメールアドレス</div>
                    <input name='email' onChange={(event) => setEmail(event.target.value)} />
                </div>
            </form>
            <div className='login-button-area'>
                <button disabled={(email === '' || email === undefined)} onClick={onTap}>変更</button>
            </div>
        </>
    );
}

export default ChangeEmail