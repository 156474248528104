import React, { useState } from 'react';
import '../asset/css/Login.scss'
import { useNavigate } from "react-router-dom"
import axios from '../axios';
import { useCookies } from 'react-cookie';

const Login = () => {
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const navigate = useNavigate()
    const [cookies, setCookie] = useCookies(['token'])

    const onSubmit = async () => {
        axios.post(
            '/login', {
            email: email,
            password: password
        }
        ).then((response) => {
            const res = response.data
            console.log(res.data.token)
            setCookie('token', res.data.token, { path: '/', maxAge: 60 * 60 * 24 });
            // ストーリー一覧画面に移動
            navigate('/');
        });
    }

    const moveToCreateAccount = async () => {
        navigate('/signUp')
    }

    return (
        <>
            <h2 class='header-text'>ログイン</h2>
            <form class='login-form'>
                <div class='email-area'>
                    <div>メールアドレス</div>
                    <input name='email' onChange={(event) => setEmail(event.target.value)} />
                </div>
                <div class='password-area'>
                    <div>パスワード</div>
                    <input type='password' name='password' onChange={(event) => setPassword(event.target.value)} />
                </div>
            </form>
            <div className='login-button-area'>
                <button disabled={(email === '' || email === undefined) ||
                    (password === '' || password === undefined)} onClick={onSubmit}>ログイン
                </button>
            </div>
            <div className='create-account-link'>
                <span onClick={moveToCreateAccount}>新規登録はこちら</span>
            </div>
        </>
    );
}

export default Login;
