import '../asset/css/StoryCell.scss';
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

const StoryCell = (props) => {
    return (
        <Link to={props.path}>
            <button class="story-cell">
                <div class="story-cell-item">
                    <div class="story-title">{props.title}</div>
                    <div class="story-detail">{props.description}</div>
                </div>
            </button>
        </Link>
    );
}

StoryCell.propsTypes = {
    path: PropTypes.element.isRequired,
    title: PropTypes.element.isRequired,
    description: PropTypes.element.isRequired,
}

export default StoryCell;