import '../asset/css/StotyDetail.scss'
import { useEffect, useState } from 'react';
import axios from "../axios";
import { BsFillTelephoneFill } from "react-icons/bs"
import { useLocation } from 'react-router-dom';

function StoryDetail() {
    const [story, setStory] = useState();
    const [isShowPopUp, setIsShowPopUp] = useState(false);
    const [sns, setSns] = useState();
    const location = useLocation();

    useEffect(() => {
        async function fetchData() {
            const searchParams = new URLSearchParams(location.search);
            const id = searchParams.get('id');
            console.log(id)
            return await axios.get(
                `/story/${id}`, {
                // headers: {
                //     "authorization": `Bearer ${token}`,
                // }
            }).then(response => {
                const res = response.data
                setStory([res.data]);
            });
        }
        fetchData();
    }, [location.search]);

    const postEvaluation = async (evaluation) => {

        const data = {
            story_id: String(story[0].id),
            sns: sns,
            evaluation: evaluation ? "1" : "0",
        }
        await axios.post(
            '/evaluation', data, {
            // headers: {
            //     "authorization": `Bearer ${token}`
            // }
        }
        ).then((response) => {
            console.log(response.data);
            setIsShowPopUp(false);
        });
    }

    const handleSNSButtonClick = () => {
        setIsShowPopUp(true);
    }

    const handleEvaluationYesButtonClick = () => {
        postEvaluation(true);
    }

    const handleEvaluationNoButtonClick = () => {
        postEvaluation(false);
    }

    const clickedYoutube = () => {
        setSns('Youtube');
        handleSNSButtonClick();
    }

    const clickedInstagram = () => {
        setSns('Instagram');
        handleSNSButtonClick();
    }

    const clickedTiktok = () => {
        setSns('Tiktok');
        handleSNSButtonClick();
    }

    return (
        <>
            <div class="container">
                {
                    story?.map(s => {
                        return (
                            <>
                                <p class="title">{s.title}</p>
                                <p class="detail">{s.description}</p>
                                <div class="icon-container">
                                    <a className="youtube-icon" href={s.youtube_url} onClick={clickedYoutube} rel="noopener noreferrer" target="_blank">youtube</a>
                                    <a className="instagram-icon" href={s.instagram_url} onClick={clickedInstagram} rel="noopener noreferrer" target="_blank">インスタグラム</a>
                                    <a className="tiktok-icon" href={s.tiktok_url} onClick={clickedTiktok} rel="noopener noreferrer" target="_blank">Tiktok</a>
                                </div>
                                <p class="shop-title">{s.shop_name}</p>
                                <div class="address-container">
                                    <p>住所</p>
                                    <div class="info-item">
                                        <div className="address-item">
                                            <p className="postal-number">〒 {s.postal_number}</p>
                                            <p class="address-text">{s.address}</p>
                                        </div>
                                        <p class="number-text"><BsFillTelephoneFill /> {s.phone_number}</p>
                                    </div>
                                </div>
                                <div className="name-content">
                                    <p>オーナー名</p>
                                    <p class="name">{s.name}</p>
                                </div>
                                <div className={`evaluation ${isShowPopUp ? 'showPopUp' : ''}`}>
                                    <div className='evaluation-pop-up'>
                                        <div className='evaluation-title'>心を動かされましたか？</div>
                                        <div className='evaluation-button-area'>
                                            <button className='evaluation-button' onClick={handleEvaluationYesButtonClick}>はい</button>
                                            <button className='evaluation-button' onClick={handleEvaluationNoButtonClick}>いいえ</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    })
                }
            </div>
        </>
    );
}

export default StoryDetail;
