import '../asset/css/StoryList.scss'
import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom"
import axios from "../axios";
import StoryCell from '../component/StoryCell';

function StoryList() {
    const [storyList, setStoryList] = useState();
    const [categoryList, setCategoryList] = useState();

    useEffect(() => {
        async function fetchData() {
            return await axios.get(
                '/story', {
                // headers: {
                //     "authorization": `Bearer ${token}`,
                // }
            }).then(response => {
                const res = response.data
                setStoryList(res.data.stories);
            });
        }
        fetchData();
        async function fetchCategoryData() {
            return await axios.get(
                '/category', {
                // headers: {
                //     "authorization": `Bearer ${token}`,
                // }
            }).then(response => {
                const res = response.data
                setCategoryList(res.data.categories);
            });
        }
        fetchCategoryData();
    }, []);


    return (
        <>
            <div class="story-category-tab">
                <nav style={{ background: "#666" }}>
                    <ul style={{ display: 'flex', listStyle: 'none' }}>
                        {
                            categoryList?.map(category => {
                                return (
                                    <li style={{ margin: 10 }}><NavLink exact to="/" style={{ color: "#fff", textDecoration: 'none' }} activeStyle={{ color: '#f00' }}>{category.title}</NavLink></li>
                                );
                            })
                        }
                    </ul>
                </nav>
            </div>
            <div class="story-list">
                {
                    storyList?.map(story => {
                        return (
                            <StoryCell path={`/detail?id=${story.id}`} title={story.title} description={story.description} />
                        );
                    })
                }
            </div>
        </>
    );
}

export default StoryList;
